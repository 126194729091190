// Views
import Container from "./components/Container";
import Login from "./components/auth/Login";
import Dashboard from "./components/dashboard/Dashboard";

require('./bootstrap');

window.Vue = require("vue").default;

import { BootstrapVue } from 'bootstrap-vue'

Vue.use(BootstrapVue)

import Toasted from 'vue-toasted';

Vue.use(Toasted)

const app = new Vue({
    el: "#app",
    components: {
        Container,
        Login,
        Dashboard,
        Convenios: () => import('./components/convenios/Convenios'),
        Descontos: () => import('./components/descontos/Descontos'),
        Tiposdeanestesia: () => import('./components/tipos-de-anestesia/TiposDeAnestesia'),
        Hospitais: () => import('./components/hospitais/Hospitais'),
        Especialidades: () => import('./components/especialidades/Especialidades'),
        Roles: () => import('./components/roles/Roles'),
        Permissions: () => import('./components/permissions/Permissions'),
        Empresas: () => import('./components/empresas/Empresas'),
        Extras: () => import('./components/extras/Extras'),
        Complications: () => import('./components/complications/Complications'),
        Equipescirurgicas: () => import('./components/equipes/EquipesCirurgicas'),
        Users: () => import('./components/users/Users'),
        UserShow: () => import('./components/users/Show'),
        Codigos: () => import('./components/codigos/Codigos'),
        Codigoshow: () => import('./components/codigos/Show'),
        Anestesistas: () => import('./components/anestesistas/Anestesistas'),
        Anestesistashow: () => import('./components/anestesistas/Show'),
        Procedimentos: () => import('./components/procedimentos/Index'),
        Pacientes: () => import('./components/pacientes/Index'),
        PacienteShow: () => import('./components/pacientes/Show'),
        ProcedimentoShow: () => import('./components/procedimentos/Show'),
        Faturamento: () => import('./components/faturamento/Aberto'),
        Pagamentos: () => import('./components/pagamentos/Index'),
        FaturamentoAnestesista: () => import('./components/faturamento/Anestesista'),
        FaturamentoGeral: () => import('./components/faturamento/Geral'),
        ProcedimentosCreate: () => import('./components/procedimentos/Create'),
        SharedDocuments: () => import('./components/shared-documents/list'),
        ReportsPanel: () => import('./components/relatorios/ReportsPanel'),
        ProcedimentosAgrupadosPorMes: () => import('./components/relatorios/ProcedimentosAgrupadosPorMes'),
        ProcedimentosAgrupadosPorEmpresa: () => import('./components/relatorios/ProcedimentosAgrupadosPorEmpresa'),
        ProcedimentosAgrupadosPorHospital: () => import('./components/relatorios/ProcedimentosAgrupadosPorHospital'),
        ProcedimentosAgrupadosPorTipoDeAnestesia: () => import('./components/relatorios/ProcedimentosAgrupadosPorTipoDeAnestesia'),
        ProcedimentosAgrupadosPorAnestesista: () => import('./components/relatorios/ProcedimentosAgrupadosPorAnestesista'),
        ProcedimentosAgrupadosPorEquipeCirurgica: () => import('./components/relatorios/ProcedimentosAgrupadosPorEquipeCirurgica'),
        ProcedimentosAgrupadosPorEspecialidade: () => import('./components/relatorios/ProcedimentosAgrupadosPorEspecialidade'),
        ProcedimentosAgrupadosPorComplicacao: () => import('./components/relatorios/ProcedimentosAgrupadosPorComplicacao'),
        ProcedimentosAgrupadosPorAsa: () => import('./components/relatorios/ProcedimentosAgrupadosPorAsa'),
        ProcedimentosAgrupadosAsaEPorHospital: () => import('./components/relatorios/ProcedimentosAgrupadosAsaEPorHospital'),
        FaturamentoAgrupadoPorMes: () => import('./components/relatorios/FaturamentoAgrupadoPorMes'),
        FaturamentoAgrupadoPorHospital: () => import('./components/relatorios/FaturamentoAgrupadoPorHospital'),
        Indicadores: () => import('./components/indicadores/Indicadores'),
        IndicadoresProcedimentos: () => import('./components/indicadores/Procedimentos'),
        IndicadoresProcedimentosPorMes: () => import('./components/indicadores/ProcedimentosPorMes'),
        IndicadoresAldrette: () => import('./components/indicadores/Aldrette'),
        IndicadoresAnestesistasPorEquipe: () => import('./components/indicadores/AnestesistasPorEquipe'),
        TextosEmail: () => import('./components/textos-email/Index'),
    }
});
