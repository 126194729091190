<template>
    <div>
        <div class="row">
            <div class="col-md-4 col-xs-6" v-if="verProcedimentos">
                <a href="/procedimentos?status=agendado">
                    <div class="small-box bg-gradient-cyan">
                        <div class="inner row">
                            <div class="col-sm-3 col-lg-12">
                                <h3>{{ agendados }}</h3>
                            </div>
                            <div class="col-sm-9 col-lg-12">
                                <p>Procedimentos agendados</p>
                            </div>
                        </div>
                        <div class="icon">
                            <i class="ion ion-android-calendar"></i>
                        </div>
                    </div>
                </a>
            </div>

            <div class="col-md-4 col-xs-6" v-if="can('listar meus procedimentos')">
                <a href="/procedimentos?status=encaminhado">
                    <div class="small-box" style="background-color: #f5a0a0">
                        <div class="inner row">
                            <div class="col-sm-3 col-lg-12">
                                <h3>{{ procedimentosEncaminhadosParaMim }}</h3>
                            </div>
                            <div class="col-sm-9 col-lg-12">
                                <p>Procedimentos encaminhados para mim</p>
                            </div>
                        </div>
                        <div class="icon">
                            <i class="ion ion-android-calendar"></i>
                        </div>
                    </div>
                </a>
            </div>

            <div class="col-md-4 col-xs-6" v-if="verProcedimentos">
                <a href="/procedimentos?status=enviado&hospital_id=2">
                    <div class="small-box" style="background-color: #89d0b0">
                        <div class="inner row">
                            <div class="col-sm-3 col-lg-12">
                                <h3>{{ enviadosCaxias }}</h3>
                            </div>
                            <div class="col-sm-9 col-lg-12">
                                <p>Procedimentos enviados Caxias D'or</p>
                            </div>
                        </div>
                        <div class="icon">
                            <i class="ion ion-ios-cloud-upload-outline"></i>
                        </div>
                    </div>
                </a>
            </div>

            <div class="col-md-4 col-xs-6" v-if="verProcedimentos">
                <a href="/procedimentos?status=enviado&hospital_id=-2">
                    <div class="small-box bg-yellow">
                        <div class="inner row">
                            <div class="col-sm-3 col-lg-12">
                                <h3>{{ enviadosOutros }}</h3>
                            </div>
                            <div class="col-sm-9 col-lg-12">
                                <p>Procedimentos enviados outros</p>
                            </div>
                        </div>
                        <div class="icon">
                            <i class="ion ion-ios-cloud-upload-outline"></i>
                        </div>
                    </div>
                </a>
            </div>

            <div class="col-md-4 col-xs-6" v-if="verProcedimentos">
                <a href="/procedimentos?status=fechado">
                    <div class="small-box bg-blue">
                        <div class="inner row">
                            <div class="col-sm-3 col-lg-12">
                                <h3>{{ fechados }}</h3>
                            </div>
                            <div class="col-sm-9 col-lg-12">
                                <p>Procedimentos fechados</p>
                            </div>
                        </div>
                        <div class="icon">
                            <i class="ion ion-ios-cloud-upload-outline"></i>
                        </div>
                    </div>
                </a>
            </div>

            <div class="col-md-4  col-xs-6" v-if="verProcedimentosTodos">
                <a href="/procedimentos?status=encaminhado">
                    <div class="small-box bg-orange">
                        <div class="inner row">
                            <div class="col-sm-3 col-lg-12">
                                <h3>{{ encaminhados }}</h3>
                            </div>
                            <div class="col-sm-9 col-lg-12">
                                <p>Procedimentos encaminhados</p>
                            </div>
                        </div>
                        <div class="icon">
                            <i class="ion ion-arrow-graph-up-right"></i>
                        </div>
                    </div>
                </a>
            </div>

            <div class="col-md-4  col-xs-6" v-if="can('listar todos os anestesistas')">
                <a href="/anestesistas">
                    <div class="small-box bg-red">
                        <div class="inner row">
                            <div class="col-sm-3 col-lg-12">
                                <h3>{{ anestesistas }}</h3>
                            </div>
                            <div class="col-sm-9 col-lg-12">
                                <p>Anestesistas</p>
                            </div>
                        </div>
                        <div class="icon">
                            <i class="ion ion-ios-heart"></i>
                        </div>
                    </div>
                </a>
            </div>
            <div class="col-md-4  col-xs-6" v-if="can('procedimentos_criar')">
                <a href="/procedimentos/create">
                    <div class="small-box bg-green">
                        <div class="inner row">
                            <div class="col-sm-3 col-lg-12">
                                <h3><i class="fa fa-plus"></i></h3>
                            </div>
                            <div class="col-sm-9 col-lg-12">
                                <p>Agendar Procedimento</p>
                            </div>
                        </div>
                        <div class="icon">
                            <i class="ion ion-arrow-right-c"></i>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import users from "../../mixins/users";

export default {
    name: "Dashboard",
    mixins: [ users ],
    props: {
        permissions: Array,
        agendados: Number,
        fechados: Number,
        encaminhados: Number,
        enviadosCaxias: Number,
        enviadosOutros: Number,
        anestesistas: Number,
        procedimentosEncaminhadosParaMim: Number,
    },
    data() {
        return {
            verProcedimentos: this.can('listar meus procedimentos') || this.can('listar todos os procedimentos'),
            verProcedimentosTodos:  this.can('listar todos os procedimentos') ||  this.can('listar procedimentos minha empresa'),
        }
    }
}
</script>

<style scoped>
a {
    text-decoration: none;
}
</style>
