<template>
    <div>
        <!-- Main Sidebar Container -->
        <aside class="main-sidebar sidebar-dark-primary elevation-4">
            <!-- Brand Logo -->
            <a href="/" class="brand-link">
                <img src="https://adminlte.io/themes/v3/dist/img/AdminLTELogo.png" alt="AdminLTE Logo" class="brand-image img-circle elevation-3"
                    style="opacity: .8">
                <span class="brand-text font-weight-light">AnestesiaPro</span>
            </a>
            <!-- Sidebar -->
            <div class="sidebar">
            <!-- Sidebar user panel (optional) -->
            <div class="user-panel mt-1 pb-1 mb-1 ml-3 d-flex">
                <div class="info font-weight-bolder" style="color: whitesmoke">
                    {{ user && user.name }}<br>
                    {{ userRoles }}
                </div>
            </div>

            <!-- Sidebar Menu -->
            <nav class="mt-2">
                <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                    <li class="nav-item">
                        <a :href="domain + `/my-account/${user.id}`" class="nav-link" :class="url.indexOf('my-account') > -1 ? 'active' : ''">
                            <i class="nav-icon fas fa-user"></i>
                            <p>Minha conta</p>
                        </a>
                    </li>

                    <li class="nav-item">
                        <a :href="domain + '/home'" class="nav-link" :class="url.indexOf('home') > -1 ? 'active' : ''">
                            <i class="nav-icon fas fa-tachometer-alt"></i>
                            <p>Painel de controle</p>
                        </a>
                    </li>

                    <li class="nav-item" v-if="can('listar perfis e permissões')">
                        <a :href="domain + '/roles'" class="nav-link" :class="url.indexOf('roles') > -1 ? 'active' : ''">
                            <i class="nav-icon fas fa-user-tag"></i>
                            <span>Perfis</span>
                        </a>
                    </li>

                    <li class="nav-item" v-if="can('listar perfis e permissões')">
                        <a :href="domain + '/permissions'" class="nav-link" :class="url.indexOf('permissions') > -1 ? 'active' : ''">
                            <i class="nav-icon fas fa-key"></i>
                            <span>Permissões</span>
                        </a>
                    </li>

                    <li class="nav-item" v-if="can('listar usuários')">
                        <a :href="domain + '/users'" class="nav-link" :class="url.indexOf('users') > -1 ? 'active' : ''">
                            <i class="nav-icon fas fa-users"></i>
                            <span>Usuários</span>
                        </a>
                    </li>

                    <li class="nav-item" v-if="can('enviar-email')">
                        <a :href="domain + '/textos-emails'" class="nav-link"
                           :class="url.indexOf('textos-emails') > -1 ? 'active' : ''">
                            <i class="nav-icon fa fa-envelope"></i>
                            <span>Emails</span>
                        </a>
                    </li>

                    <li class="nav-item"
                        v-if="can('listar todos os anestesistas') || can('listar anestesistas minha empresa')">
                        <a :href="domain + '/anestesistas'" class="nav-link"  :class="url.indexOf('anestesistas') > -1 ? 'active' : ''">
                            <i class="nav-icon fas fa-stethoscope"></i>
                            <span>Anestesistas</span>
                        </a>
                    </li>

                    <li class="nav-item"
                        v-if="can('listar pacientes')">
                        <a :href="domain + '/pacientes'" class="nav-link"  :class="url.indexOf('pacientes') > -1 ? 'active' : ''">
                            <i class="nav-icon fas fa-head-side-cough"></i>
                            <span>Pacientes</span>
                        </a>
                    </li>

                    <li class="nav-item" v-if="can('listar convênios')">
                        <a :href="domain + '/convenios'" class="nav-link" :class="url.indexOf('convenios') > -1 ? 'active' : ''">
                            <i class="nav-icon far fa-id-card"></i>
                            <span>Convênios</span>
                        </a>
                    </li>

                    <li class="nav-item" v-if="can('listar tipos de anestesia')">
                        <a :href="domain + '/tipos-de-anestesia'" class="nav-link"  :class="url.indexOf('tipos-de-anestesia') > -1 ? 'active' : ''">
                            <i class="nav-icon fas fa-list"></i>
                            <span>Tipos de anestesia</span>
                        </a>
                    </li>

                    <li class="nav-item" v-if="can('listar hospitais')">
                        <a :href="domain + '/hospitals'" class="nav-link"  :class="url.indexOf('hospitals') > -1 ? 'active' : ''">
                            <i class="nav-icon fas fa-hospital"></i>
                            <span>Hospitais</span>
                        </a>
                    </li>

                    <li class="nav-item" v-if="can('listar especialidades')">
                        <a :href="domain + '/especialidades'" class="nav-link"  :class="url.indexOf('especialidades') > -1 ? 'active' : ''">
                            <i class="nav-icon fas fa-graduation-cap"></i>
                            <span>Especialidades</span>
                        </a>
                    </li>

                    <li class="nav-item" v-if="can('listar equipes cirúrgicas')">
                        <a :href="domain + '/equipes'" class="nav-link"  :class="url.indexOf('equipes') > -1 ? 'active' : ''">
                            <i class="nav-icon fas fa-user-md"></i>
                            <span>Equipes cirúrgicas</span>
                        </a>
                    </li>

                    <li class="nav-item" v-if="can('listar empresas')">
                        <a :href="domain + '/empresas'" class="nav-link"  :class="url.indexOf('empresas') > -1 ? 'active' : ''">
                            <i class="nav-icon fas fa-briefcase"></i>
                            <span>Empresas</span>
                        </a>
                    </li>

                    <li class="nav-item" v-if="can('listar extras')">
                        <a :href="domain + '/extras'" class="nav-link"  :class="url.indexOf('extras') > -1 ? 'active' : ''">
                            <i class="nav-icon fas fa-walking"></i>
                            <span>Anestesistas externos</span>
                        </a>
                    </li>

                    <li class="nav-item" v-if="can('listar codigos')">
                        <a :href="domain + '/codigos'" class="nav-link"  :class="url.indexOf('codigos') > -1 ? 'active' : ''">
                            <i class="nav-icon fas fa-tags"></i>
                            <span>Códigos</span>
                        </a>
                    </li>

                    <li class="nav-item" v-if="can('listar complicações')">
                        <a :href="domain + '/complications'" class="nav-link"  :class="url.indexOf('complications') > -1 ? 'active' : ''">
                            <i class="nav-icon fas fa-notes-medical"></i>
                            <span>Complicações</span>
                        </a>
                    </li>

                    <li class="nav-item" v-if="can('listar meus procedimentos') || can('listar todos os procedimentos')">
                        <a :href="domain + '/procedimentos'" class="nav-link" :class="url.indexOf(domain + '/procedimentos') > -1 ? 'active' : ''">
                            <i class="nav-icon fas fa-procedures"></i>
                            <span>Procedimentos</span>
                        </a>
                    </li>

                    <li class="nav-item" v-if="can('pagamentos_listar_meus') || can('pagamentos_listar_todos')">
                        <a href="#" class="nav-link">
                        <i class="nav-icon fas fa-dollar-sign"></i>
                        <p>
                           Faturamento
                            <i :class="menuFaturamento ? 'right fas fa-angle-down' : 'right fas fa-angle-left'"></i>
                        </p>
                        </a>
                        <ul class="nav nav-treeview" :style="{display: menuFaturamento ? 'block' : 'none'}">
                            <li class="nav-item ml-2" v-if="can('pagamentos_listar_todos')">
                                <a  :href="domain + '/faturamento/aberto'" class="nav-link"
                                    :class="url.indexOf('faturamento/aberto') > -1 ? 'active' : ''">
                                <i :class="url.indexOf('faturamento/aberto')>-1 ? 'far fa-check-circle nav-icon'
                                    : 'far fa-circle nav-icon'"></i>
                                <p>Faturamento em aberto</p>
                                </a>
                            </li>
                            <li class="nav-item ml-2" v-if="can('pagamentos_listar_todos')">
                                <a  :href="domain + '/pagamentos'" class="nav-link"
                                    :class="url.indexOf('pagamentos') > -1 ? 'active' : ''">
                                    <i :class="url.indexOf('pagamentos')>-1 ? 'far fa-check-circle nav-icon'
                                        : 'far fa-circle nav-icon'"></i>
                                    <p>Pagamentos</p>
                                </a>
                            </li>
                            <li class="nav-item ml-2">
                                <a  :href="domain + '/faturamento/anestesista'" class="nav-link"
                                    :class="url.indexOf('faturamento/anestesista') > -1 ? 'active' : ''">
                                    <i :class="url.indexOf('faturamento/anestesista')>-1 ? 'far fa-check-circle nav-icon'
                                        : 'far fa-circle nav-icon'"></i>
                                    <p>Faturamento anestesista</p>
                                </a>
                            </li>
                            <li class="nav-item ml-2" v-if="can('pagamentos_listar_todos')">
                                <a  :href="domain + '/faturamento/geral'" class="nav-link"
                                    :class="url.indexOf('faturamento/geral') > -1 ? 'active' : ''">
                                    <i :class="url.indexOf('faturamento/geral')>-1 ? 'far fa-check-circle nav-icon'
                                        : 'far fa-circle nav-icon'"></i>
                                    <p>Faturamento geral</p>
                                </a>
                            </li>
                            <li class="nav-item ml-2" v-if="can('descontos_gerenciar')">
                                <a  :href="domain + '/descontos'" class="nav-link"
                                    :class="url.indexOf('descontos') > -1 ? 'active' : ''">
                                    <i :class="url.indexOf('descontos')>-1 ? 'far fa-check-circle nav-icon'
                                        : 'far fa-circle nav-icon'"></i>
                                    <p>Descontos</p>
                                </a>
                            </li>
                        </ul>
                    </li>

                    <li class="nav-item" v-if="can('listar documentos comuns')">
                        <a href="#" class="nav-link">
                            <i class="nav-icon fas fa-file-medical"></i>
                            <p>
                                Documentos
                                <i :class="menuDocumentos ? 'right fas fa-angle-down' : 'right fas fa-angle-left'"></i>
                            </p>
                        </a>

                        <ul class="nav nav-treeview" :style="{display: menuDocumentos ? 'block' : 'none'}">
                            <li class="nav-item ml-2">
                                <a  :href="domain + '/shared-documents?type=preoperatorio'" class="nav-link"
                                    :class="url.indexOf('preoperatorio') > -1 ? 'active' : ''">
                                    <i :class="url.indexOf('preoperatorio')>-1 ? 'far fa-check-circle nav-icon'
                                    : 'far fa-circle nav-icon'"></i>
                                    <p>Pré-operatório</p>
                                </a>
                            </li>
                            <li class="nav-item ml-2">
                                <a  :href="domain + '/shared-documents?type=orcamento'" class="nav-link"
                                    :class="url.indexOf('orcamento') > -1 ? 'active' : ''">
                                    <i :class="url.indexOf('orcamento')>-1 ? 'far fa-check-circle nav-icon'
                                    : 'far fa-circle nav-icon'"></i>
                                    <p>Orçamento</p>
                                </a>
                            </li>
                            <li class="nav-item ml-2">
                                <a  :href="domain + '/shared-documents?type=cobranca'" class="nav-link"
                                    :class="url.indexOf('cobranca') > -1 ? 'active' : ''">
                                    <i :class="url.indexOf('cobranca')>-1 ? 'far fa-check-circle nav-icon'
                                    : 'far fa-circle nav-icon'"></i>
                                    <p>Cobrança</p>
                                </a>
                            </li>
                            <li class="nav-item ml-2">
                                <a  :href="domain + '/shared-documents?type=convite'" class="nav-link"
                                    :class="url.indexOf('convite') > -1 ? 'active' : ''">
                                    <i :class="url.indexOf('convite')>-1 ? 'far fa-check-circle nav-icon'
                                    : 'far fa-circle nav-icon'"></i>
                                    <p>Convite anestesista</p>
                                </a>
                            </li>
                        </ul>
                    </li>

                    <li class="nav-item" v-if="can('relatorios_ver')">
                        <a href="#" class="nav-link">
                            <i class="nav-icon fas fa-chart-line"></i>
                            <p>
                                Relatórios
                                <i :class="menuDocumentos ? 'right fas fa-angle-down' : 'right fas fa-angle-left'"></i>
                            </p>
                        </a>

                        <ul class="nav nav-treeview" :style="{display: menuDocumentos ? 'block' : 'none'}">
                            <li class="nav-item ml-2">
                                <a  :href="domain + '/relatorios/procedimento'" class="nav-link"
                                    :class="url.indexOf('relatorios/procedimento') > -1 ? 'active' : ''">
                                    <i :class="url.indexOf('relatorios/procedimento')>-1 ? 'far fa-check-circle nav-icon'
                                    : 'far fa-circle nav-icon'"></i>
                                    <p>Procedimentos</p>
                                </a>
                            </li>

                            <li class="nav-item ml-2">
                                <a  :href="domain + '/relatorios/faturamento'" class="nav-link"
                                    :class="url.indexOf('relatorios/faturamento') > -1 ? 'active' : ''">
                                    <i :class="url.indexOf('relatorios/faturamento')>-1 ? 'far fa-check-circle nav-icon'
                                    : 'far fa-circle nav-icon'"></i>
                                    <p>Faturamento</p>
                                </a>
                            </li>
                        </ul>
                    </li>

                    <li class="nav-item" v-if="can('relatorios_ver')">
                        <a :href="domain + '/relatorios/indicadores'" class="nav-link"
                           :class="url.indexOf(domain + '/indicadores') > -1 ? 'active' : ''">
                            <i class="nav-icon fas fa-info-circle"></i>
                            <span>Indicadores</span>
                        </a>
                    </li>
                </ul>
            </nav>
            <!-- /.sidebar-menu -->
            </div>
            <!-- /.sidebar -->
        </aside>
    </div>
</template>

<script>
import users from "../../mixins/users";
export default {
    name: "Sidebar",
    props: ['user', 'domain', 'permissions'],
    mixins: [ users ],
    data() {
        return {
            url: location.href,
        }
    },
    computed: {
        menuFaturamento() {
            return this.url.indexOf('faturamento') > -1 || this.url.indexOf('pagamentos') > -1;
        },
        menuDocumentos() {
            return this.url.indexOf('shared-documents') > -1;
        },
        userRoles() {
            if (this.user && this.user.roles.length) {
                return this.user.roles.reduce((sum, role)=>sum.concat([role.name]), []).join(', ');
            } else {
                return null;
            }
        }
    },
}
</script>

<style scoped>
a.nav-link{
    padding: 5px 14px;
}
</style>
