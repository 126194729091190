<template>
    <div>
        <footer class="main-footer">
            <strong>Copyright &copy; 2018 - 2022 <a href="http://anestesiapro.com.br">AnestesiaPro</a>.</strong>
            Todos os direitos reservados.
        </footer>
    </div>
</template>

<script>
export default {
    name: "Footer"
}
</script>
