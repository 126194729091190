/**
 * Supõem que acha uma propriedade permissions
 */
export default {
    data() {
        return {
        }
    },
    methods: {
        can(permission) {
            return this.permissions.indexOf(permission) > -1;
        }
    }
}
