<template>
    <div>
        <Navbar :user="user" />

        <Sidebar :user="user" :domain="domain" :permissions="permissions"/>
        <!-- Content Wrapper. Contains page content -->
        <div class="content-wrapper">
            <!-- Main content -->
            <section class="content" style="padding: 0.5rem 1.5rem">
                <slot name="content"></slot>
            </section>
            <!-- /.content -->
        </div>
        <Footer />
    </div>
</template>

<script>
import Navbar from './global/Navbar'
import Sidebar from './global/Sidebar'
import Footer from './global/Footer'
export default {
    name: "Container",
    props: ['user', 'permissions', 'domain'],
    components: { Footer, Sidebar, Navbar },
}
</script>
